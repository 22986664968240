import axios from '@/utils/http'
/* 登录 */
export const login = (params = {}) => axios.post('/api/auth/login', params)

/* 退出 */
export const logout = (params = {}) => axios.post('/api/auth/logout')

/* 生成验证码 */
export const authCode = (params = {}) => axios.get('/api/auth/code', { params })

/* 用户信息 */
export const userInfo = (params = {}) => axios.get('/api/auth/getUserInfo', { params })

/* 获取动态路由 */
export const getPermissions = (params = {}) => axios.get('/api/auth/getPermissions', { params })

export const upload = (params = {}) => axios.post('https://www.imgurl.org/upload/aws_s3', params)

/* 工厂端登录 */
export const factoryLogin = (params = {}) => axios.post('/api/auth/loginFactory', params)

export const getFactoryMenu = (params = {}) => axios.get('/api/auth/getFactoryMenu', { params })

export const FactoryUserInfo = (params = {}) => axios.get('/api/auth/getFactoryUserInfo', { params })

export const checkFactoryDetail = (params) => axios.post('/api/wxLogin/checkFactoryDetail', params)

/* 管理端登录 */
export const loginAgent = (params = {}) => axios.post('/api/auth/loginAgent', params)

export const getAgentMenu = (params = {}) => axios.get('/api/auth/getAgentMenu', { params })

export const getAgentUserInfo = (params = {}) => axios.get('/api/auth/getAgentUserInfo', { params })

/**
 * 工厂端忘记密码获取手机验证码
 * @param params 手机号
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendMessage = (params = {}) => axios.post('/api/auth/SendMessage', params)

/**
 * 工厂段重置密码
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const retrievePassword = (params = {}) => axios.post('/api/auth/RetrievePassword', params)

// 代理商重置密码
export const agentRetrievePassword = (params = {}) => axios.post('/api/auth/agentRetrievePassword', params)

// 下载说明书
export const getInstructions = (data) => axios.post('/api/orderProduction/getInstructions', data)

//查询消息通知列表
export const getMessageInfoDetails = (data) => axios.post('/api/message/getMessageInfoDetails', data)

// 消息条数
export const getMessageNum = () => axios.post('/api/message/getMessageInfo')

// 一键消息条数
export const updateByUserIds = (ids) => axios.post(`/api/message/updateByUserIds/${ids}`)


